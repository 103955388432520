import { ParsedUrlQuery, stringify } from 'querystring';
import { useEffect, useState } from 'react';

import { getDeviceId } from '@/utils/amplitude';

export function useAddDeviceIdToQuery(
  linkQuery: string | ParsedUrlQuery | undefined,
) {
  const [query, setQuery] = useState<string | undefined>(
    linkQuery ? linkQuery?.toString() : linkQuery,
  );

  useEffect(() => {
    if (linkQuery) {
      if (typeof linkQuery === 'string') {
        return setQuery(linkQuery + '&device_id=' + getDeviceId());
      }

      const parsedQuery = linkQuery as ParsedUrlQuery;

      parsedQuery.device_id = getDeviceId();

      return setQuery(stringify(parsedQuery));
    }
  }, [linkQuery]);

  return query;
}
