'use client';

import { useResponsiveQuery } from 'atomic-layout';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { tv, VariantProps } from 'tailwind-variants';

import { CMSEventParams, useAnalytics } from '@/app/hooks/useAnalytics';
import { LegacyButtonColor } from '@/components/Button';
import Icon, { IconsNames } from '@/components/v3/Icon';
import { useQRCodeDrawer } from '@/contexts/qrcodeDrawer';
import { stripHtml } from '@/helpers/html';
import { useAddDeviceIdToQuery } from '@/hooks/useAddDeviceIdToQuery';
import { useCMSLink } from '@/hooks/useCMSLink';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';

import Loading from './Loading';

const button = tv({
  base: 'btn w-full md:w-auto font-medium whitespace-nowrap',
  variants: {
    color: {
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      primaryNeon: 'btn-primary-neon',
      ghost: 'btn-ghost',
      transparent: 'btn-transparent',
      ton: 'btn-ton',
    },
    size: {
      small: 'btn-small',
      regular: 'btn-regular',
      large: 'btn-large',
    },
  },
  defaultVariants: {
    size: 'large',
    color: 'primary',
  },
});

const link = tv({
  base: 'flex gap-4 md:p-0 px-16 pb-8 w-max max-w-[620px] md:max-w-none text-[16px] font-medium leading-[24px] paragraph-16 whitespace-nowrap',
  variants: {
    color: {
      primary: 'text-stone-700',
      secondary: 'text-stone-300',
      primaryNeon: 'text-stone-300',
      ghost: 'text-black',
      transparent: 'text-transparent',
      ton: 'text-lime-300',
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

type ButtonColor = keyof typeof button.variants.color;

export type ButtonProps = {
  /* CMS props */
  label?: string;
  color?: ButtonColor;
  size?: string;
  linkPathname?: string;
  linkTarget?: string;
  linkQuery?: string;
  linkHash?: string;
  linkHref?: string;
  iconName?: IconsNames;
  iconPosition?: 'left' | 'right';
  handleAppDownload?: boolean;
  uiStyleVariant?: 'link' | 'button';
  trackClick?: CMSEventParams[];
  linkQrCode?: string;
  title?: string;
  description?: string;

  /* Internal props */
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  preFetch?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  sectionReference: string;
  'data-testid'?: string;
  plan?: string;
} & VariantProps<typeof button>;

function ButtonContent({
  isLoading,
  iconName,
  iconPosition,
  label,
  children,
}: ButtonProps) {
  return (
    <Loading isLoading={!!isLoading}>
      {iconName && iconPosition === 'left' && (
        <Icon name={iconName} className="fill-current" />
      )}
      {label && (
        <span
          dangerouslySetInnerHTML={{
            __html: label || '',
          }}
        />
      )}
      {children}
      {iconName && iconPosition === 'right' && (
        <Icon name={iconName} className="fill-current" />
      )}
    </Loading>
  );
}
export interface ParsedUrlQuery {
  [key: string]: string | string[] | undefined;
}

export function Button(props: ButtonProps) {
  const {
    color,
    size,
    type = 'button',
    preFetch = true,
    className = '',
    linkPathname,
    linkQuery,
    linkHash,
    linkHref,
    linkTarget,
    isLoading,
    disabled,
    handleAppDownload = false,
    uiStyleVariant = 'button',
    onClick,
    trackClick,
    plan,
    label,
    sectionReference,
    linkQrCode,
    description,
    title,
  } = props;

  const { sendEvents } = useAnalytics();
  const { setQrCodeLink, toggleDrawer, setDescription, setTitle } =
    useQRCodeDrawer();
  const isMobileOrTablet = useResponsiveQuery({ to: 'lg' });
  const router = useRouter();

  const { getHrefFromCMSParams } = useCMSLink();

  const query = useAddDeviceIdToQuery(linkQuery);

  function triggerEvents() {
    let events = trackClick || [];

    const defaultFields = {
      name: 'cta stone',
      plan: plan ? plan : '',
      description: `Evento disparado ao clicar no CTA na seção "${stripHtml(
        sectionReference,
      )}"`,
    };

    if (!trackClick?.length) {
      events = [defaultFields];
    }

    events = events.map(event => ({
      ...event,
      name: event.name || defaultFields.name,
      description: event.description || defaultFields.description,
    }));

    sendEvents(events, {
      cta: label,
      section: stripHtml(sectionReference),
    });
  }

  function handleLinkClick() {
    triggerEvents();
  }

  function handleButtonClick() {
    triggerEvents();

    if (onClick) {
      onClick();
    }

    if (!handleAppDownload) return;

    if (!linkQrCode) {
      toggleDrawer();
      return;
    }

    const qrCodeUrl = handleAppsflyerUtmUrl(linkQrCode);

    if (isMobileOrTablet) {
      router.push(qrCodeUrl);
      return;
    }
    if (description && title) {
      setDescription(description);
      setTitle(title);
    }

    setQrCodeLink(qrCodeUrl);
    toggleDrawer();
  }

  return linkPathname || linkHash || linkQuery || linkHref ? (
    <Link
      href={getHrefFromCMSParams({
        hash: linkHash,
        pathname: linkPathname,
        query,
        href: linkHref,
      })}
      target={linkTarget}
      prefetch={preFetch}
      className={classNames({
        [button({ color, size, className })]: uiStyleVariant === 'button',
        [link({ color, className })]: uiStyleVariant === 'link',
      })}
      onClick={handleLinkClick}
      data-testid={props['data-testid']}
    >
      <ButtonContent {...props} />
    </Link>
  ) : (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classNames({
        [button({ color, size, className })]: uiStyleVariant === 'button',
        [link({ color, className })]: uiStyleVariant === 'link',
      })}
      onClick={handleButtonClick}
      data-testid={props['data-testid']}
    >
      <ButtonContent {...props} />
    </button>
  );
}

export default Button;

export function convertLegacyColorToNewColor(
  color?: LegacyButtonColor,
): ButtonColor | undefined {
  return color === 'stonePrimary'
    ? 'primaryNeon'
    : color === 'ton'
    ? 'ton'
    : color;
}
